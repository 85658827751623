(function () {
  'use strict';

  /* @ngdoc object
   * @name neo.services.auth
   * @description
   *
   */
  angular
  .module('neo.services.auth', [
    'ngResource'
  ]);
}());
